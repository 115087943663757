<template>
  <div class="inline-flex items-end flex-auto min-w-min shrink-0">
    <button
      v-tooltip="tooltip"
      type="button"
      @click.prevent="copyToClipboard"
      class="inline-flex items-center justify-center flex-1 w-full px-4 py-2 text-sm font-medium bg-transparent bg-white border rounded-md shadow-sm text-neutral-400 hover:text-neutral-100 hover:border-neutral-400 border-neutral-600 hover:bg-neutral-600 shrink-0 md:flex-auto md:flex-grow-0 hover:bg-gray-50"
    >
      <fa-icon icon="link" class="mr-2 -ml-1 fa-fw" />Copy link
    </button>
  </div>
</template>
<script>
export default {
  props: {
    share: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      showCopySuccess: false,
      tooltipDelay: 250
    }
  },

  methods: {
    copyToClipboard() {
      if (this.showCopySuccess === true) return

      navigator.clipboard
        .writeText(this.share.url)
        .then(this.showCopied(), function () {
          console.error('Copy failed')
        })
    },

    showCopied() {
      this.showCopySuccess = true
      setTimeout(() => {
        this.showCopySuccess = false
      }, 5000)
    }
  },

  computed: {
    tooltip() {
      let content = 'Click to copy to clipboard'
      if (this.showCopySuccess) content = 'Copied'

      return {
        delay: this.tooltipDelay,
        content: content,
        hideOnTargetClick: false
      }
    }
  }
}
</script>
